<template>
<div class="modal fade " id="TechnicianBankAccountUpdate" tabindex="-1" role="dialog" aria-labelledby="TechnicianBankAccountUpdate" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Update Bank Account Information</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Bank Name</label>
                            <input v-model="bankInformationData.bank_name" type="text" class="form-control " placeholder="Enter bank name.">

                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Account Name</label>
                            <input v-model="bankInformationData.account_name" type="text" class="form-control " placeholder="Enter account name.">

                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Account Number</label>
                            <input v-model="bankInformationData.account_number" type="text" class="form-control " placeholder="Enter account number.">

                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>BSB</label>
                            <input v-model="bankInformationData.bsb" type="text" class="form-control " placeholder="Enter BSB.">

                        </div>
                    </div>
                </div>
                <!-- 
                <h5>Other Information</h5>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>ABN</label>
                            <input v-model="putEmployeeData.abn" type="text" class="form-control " placeholder="Enter ABN">

                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="controls">
                                <label>Team Lead Status</label>
                                <div class="" style="margin-top: .5rem">
                                    <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                        <div>
                                            <input v-model="employeeTeamLeadStatus" type="checkbox" class="custom-control-input" checked id="employeeTeamLeadStatusCheckbox">
                                            <label class="custom-control-label mr-1" for="employeeTeamLeadStatusCheckbox"></label>
                                        </div>
                                        <span class="font-medium-1 text-success">{{employeeTeamLeadStatus ? "Active" : "Inactive"}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>Date of Birth</label>
                        <div class="d-flex align-items-center date-picker-wrapper">
                            <div class="d-flex align-items-center">
                                <i class='bx bx-calendar'></i>
                            </div>
                            <div class="w-100 pl-1 ">
                                <div class="w-100 ">
                                    <DatePicker v-model="putUserData.date_of_birth" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}" :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <input class="form-control" :value="inputValue" v-on="inputEvents" />
                                        </template>
                                    </DatePicker>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> -->

            </div>

            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="updateBankInformation">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Save Changes</span>
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import {

    mapActions
} from 'vuex';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
// import VueMultiselect from 'vue-multiselect';
// import {
//     DatePicker
// } from 'v-calendar';
export default {
    mixins: [ShowToastMessage],
    name: "TechnicianIdInformationUpdate",
    props: ['employee', 'user'],
    components: {

        // DatePicker,

        // VueMultiselect,
    },
    data() {
        return {
            errors: {},
            user_id: '',
            id: '',
            bankInformationData: {

                bank_name: '',
                account_name: '',
                account_number: '',
                bsb: '',

            },

        }
    },
    watch: {
        user(currentUser) {
            this.user_id = currentUser.id;

            this.id = currentUser.bankAccount.id;
            this.bankInformationData.bank_name = currentUser.bankAccount.bankName;
            this.bankInformationData.account_name = currentUser.bankAccount.accountName;
            this.bankInformationData.account_number = currentUser.bankAccount.accountNumber;
            this.bankInformationData.bsb = currentUser.bankAccount.bsb;
        },

    },
    methods: {
        ...mapActions({
            putBankAccount: 'appBankAccounts/putBankAccount',
            postBankAccount: 'appBankAccounts/postBankAccount',

        }),

        async updateBankInformation() {

            if (this.id) {

                const dataObj = {
                    id: this.id,
                    data: this.bankInformationData,
                };
                const response = await this.putBankAccount(dataObj);
                console.log(response);

                if (response.status === 200 || response.status === 201) {
                    document.querySelector('[data-target="#TechnicianBankAccountUpdate"]').click();

                    this.$emit('getSingleTechnician', this.$route.params.id);

                    this.errors = {};
                    this.showToastMessage(response.message);
                } else {
                    this.errors = response.errors;
                }
                if (response.message) {
                    this.showToastMessage(response);
                }

            } else {

                const postBankAccountData = {
                    user_id: this.user_id,
                    bank_name: this.bankInformationData.bank_name,
                    account_name: this.bankInformationData.account_name,
                    account_number: this.bankInformationData.account_number,
                    bsb: this.bankInformationData.bsb,

                }

                const response = await this.postBankAccount(postBankAccountData);
                console.log(response);

                if (response.status === 200 || response.status === 201) {
                    document.querySelector('[data-target="#TechnicianBankAccountUpdate"]').click();

                    this.$emit('getSingleTechnician', this.$route.params.id);

                    this.errors = {};
                    this.showToastMessage(response.message);
                } else {
                    this.errors = response.errors;
                }
                if (response.message) {
                    this.showToastMessage(response);
                }

            }

        }

    },

}
</script>

<style>

</style>
