<script>
export default {
  name: "ReferralsUpdateModal",
  props: {
    referrals: {
      type: Array,
      required: true,
      default: () => []
    },
    selectedReferral: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  emits:['updateReferralData'],
  data() {
    return {
      selectedSource: ''
    }
  },
  watch:{
    selectedReferral(currentValues){
      this.selectedSource = [...currentValues];
    }
  },
  methods:{
    async updateReferral(){
      this.$emit('updateReferralData', this.selectedSource);
    }
  },
  beforeMount() {
    this.selectedSource = ''
  },
  beforeUnmount() {
    this.selectedSource = ''
  }
}
</script>

<template>
  <div class="modal fade " id="referralsUpdateModal" tabindex="-1" role="dialog" aria-labelledby="referralsUpdateModal"
       aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Update Work For Information</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
<!--            <div class="col-12">
              <h5>Please Select Technician Work For Referral</h5>
            </div>-->
            <div class="col-12">
              <div class="form-group">
                <label>Referral</label>
                <VueMultiselect v-model="selectedSource"
                                :options="referrals"
                                :close-on-select="true"
                                :multiple="true"
                                :show-labels="false"
                                placeholder="Select referral"
                                label="name"
                                track-by="name"/>
                <div>
                  <p class="text-danger mb-1">
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" @click="updateReferral">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Save Changes</span>
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>