<template>
<div class="modal fade " id="TechnicianEmployeeTeamLeadInformationUpdate" tabindex="-1" role="dialog" aria-labelledby="TechnicianEmployeeTeamLeadInformationUpdate" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Other Information</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">

                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="controls">
                                <label>Team Lead Status</label>
                                <div class="" style="margin-top: .5rem">
                                    <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                        <div>
                                            <input v-model="employeeTeamLeadStatus" type="checkbox" class="custom-control-input" checked id="employeeTeamLeadStatusCheckbox">
                                            <label class="custom-control-label mr-1" for="employeeTeamLeadStatusCheckbox"></label>
                                        </div>
                                        <!-- <span class="font-medium-1 text-success">{{employeeTeamLeadStatus ? "Active" : "Inactive"}}</span> -->
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="updateOtherInformation">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Save Changes</span>
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import {

    mapActions
} from 'vuex';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
// import VueMultiselect from 'vue-multiselect';
// import {
//     DatePicker
// } from 'v-calendar';
export default {
    mixins: [ShowToastMessage],
    name: "TechnicianEmployeeTeamLeadInformationUpdate",
    props: ['employee', 'user', ],
    components: {

        // DatePicker,

        // VueMultiselect,
    },
    data() {
        return {
            errors: {},
            user_id: '',
            employee_id:'',

            employeeTeamLeadStatus: false,

            teamLeader: {
                id: '',
                status: '',

            },

        }
    },
    watch: {
        employee(currentEmployee) {
            
            this.employee_id = currentEmployee.id;
            this.teamLeader.id = currentEmployee.teamLead.id;
            this.teamLeader.status = currentEmployee.teamLead.status;
            this.employeeTeamLeadStatus = currentEmployee.teamLead.status ? true : false;
        },
        user(currentUser) {
            this.user_id = currentUser.id;
        },

    },
    methods: {
        ...mapActions({
            putTeamLead: 'appTeamLeads/putTeamLead',
            postTeamLead: 'appTeamLeads/postTeamLead',

        }),

        async updateOtherInformation() {

            if (this.teamLeader.id) {

                const dataObj = {
                    id: this.teamLeader.id,
                    data: {
                        status: this.employeeTeamLeadStatus ? 1 : 0,
                    },
                };
                const response = await this.putTeamLead(dataObj);
              

                if (response.status === 200 || response.status === 201) {
                    document.querySelector('[data-target="#TechnicianEmployeeTeamLeadInformationUpdate"]').click();

                    this.$emit('getSingleTechnician', this.$route.params.id);

                    this.errors = {};
                    this.showToastMessage(response.message);
                } else {
                    this.errors = response.errors;
                }
                if (response.message) {
                    this.showToastMessage(response);
                }

            } else {

                const postTeamLeadData = {
                    user_id: this.user_id,
                    employee_id: this.employee_id,
                    status: this.employeeTeamLeadStatus ? 1 : 0
                };
                const response = await this.postTeamLead(postTeamLeadData);
               

                if (response.status === 200 || response.status === 201) {
                    document.querySelector('[data-target="#TechnicianEmployeeTeamLeadInformationUpdate"]').click();

                    this.$emit('getSingleTechnician', this.$route.params.id);

                    this.errors = {};
                    this.showToastMessage(response.message);
                } else {
                    this.errors = response.errors;
                }
                if (response.message) {
                    this.showToastMessage(response);
                }

            }

        },

    },

}
</script>

<style>

</style>
